
import Vue from "vue";
import DescriptionTable from "@/modules/managers/components/DescriptionTable.vue";
import moment from "moment";

export default Vue.extend({
  name: "Tasks",
  components: { DescriptionTable },

  data: () => ({
    headers: [
      {
        text: "ID",
        value: "id",
        sortable: false
      },
      {
        text: "Title",
        value: "title",
        width: "150px",
        sortable: false
      },
      {
        text: "Description",
        value: "description",
        width: "250px",
        sortable: false
      },
      {
        text: "Author",
        value: "created_by",
        sortable: false
      },
      {
        text: "Assigned",
        value: "assigned_to",
        sortable: false
      },
      {
        text: "Client",
        value: "client_id",
        sortable: false
      },
      {
        text: "Due date",
        value: "deadline",
        sortable: false
      },
      {
        text: "Status",
        value: "status",
        sortable: false
      },
      {
        text: "Created",
        value: "created_at",
        sortable: false
      }
    ],
    items: [] as Array<any>,
    clientsByManager: [] as Array<any>,
    loading: false as boolean,
    dialogIsOpen: false,
    datePicker: false,
    dateMenu: false,
    searchClient: false as boolean,
    loadingClients: true as boolean,
    filter: {
      manager: null as any,
      client: null as any
    },
    form: {
      id: "",
      created_by: "",
      assigned_to: "",
      client_id: "",
      title: "",
      description: "",
      deadline: "",
      status: "new"
    },
    formRules: {
      title: [
        (value: string) => {
          if (value) return true;
          return "Title is required";
        }
      ],
      manager: [
        (value: string) => {
          if (value) return true;
          return "Manager field is required";
        }
      ],
      client: [
        (value: string) => {
          if (value) return true;
          return "Client field is required";
        }
      ]
    },
    valid: false as boolean,
    managers: [] as Array<any>,
    loadManagers: false as boolean,
    users: [] as Array<any>,
    editIndex: -1 as number,
    dialogDelete: false as boolean,
    showCompletedItems: false as boolean,
    clientID: null as any,
    user: null as any
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      next(vm => {
        vm.clientID = to.params.client_id;
      });
    } catch (e) {
      await this.$store.dispatch("alert/showError", e.message);
    }
  },

  async created() {
    // Get the authenticated user to make it the author of the task
    const authUser = this.$store.getters["authentication/credentials"];
    this.user = authUser.user;
    this.form.created_by = this.user.id;
    this.clientID = this.$route.params.client_id;

    // Get tasks
    await this.getTasks();
  },

  async mounted() {
    // Get managers to populate the select
    await this.getManagers();

    // Get users
    await this.getUsers();
  },

  methods: {
    // Get Tasks
    async getTasks() {
      try {
        this.loading = true;
        const params = {
          client_id: this.clientID,
          assigned_to: null as any
        };
        if (this.user.role === "manager") params.assigned_to = this.user.id;

        const response = await this.$API.user().getTasks(params);
        if (response.success) {
          this.items = response.data;
        } else {
          await this.$store.dispatch("alert/showError", response.message);
        }
        this.loading = false;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    // Save the task form handler
    async saveTask() {
      if (this.editIndex > -1) await this.updateTask();
      else await this.addTask();

      this.editIndex = -1;
      this.dialogIsOpen = false;
    },

    // Add task
    async addTask() {
      try {
        const response = await this.$API.user().storeTask(this.form);
        if (response.success) {
          await this.getTasks();
          await this.$store.dispatch("alert/showSuccess", response.message);
          const refForm: any = this.$refs.form;
          refForm.reset();
        } else {
          await this.$store.dispatch("alert/showError", response.message);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    // Update the task
    async updateTask() {
      try {
        const params = {
          title: this.form.title,
          description: this.form.description,
          deadline: this.form.deadline,
          assigned_to: this.form.assigned_to,
          created_by: this.form.created_by,
          client_id: this.form.client_id
        };
        const response = await this.$API
          .user()
          .updateTask(this.form.id, params);
        if (response.success) {
          await this.getTasks();
          await this.$store.dispatch("alert/showSuccess", response.message);
          const refForm: any = this.$refs.form;
          refForm.reset();
        } else {
          await this.$store.dispatch("alert/showError", response.message);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    // Get users
    async getUsers() {
      try {
        const response = await this.$API.user().getList();
        if(response){
          this.users = response.filter((item: any) => {
            return item.position_name === "Guest";
          });
        }
        this.loadingClients = false;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
        this.loadingClients = false;
      }
    },

    // Get managers
    async getManagers() {
      try {
        this.loadManagers = true;
        const params = {
          page: {
            number: 1,
            size: 20
          }
        };
        const response = await this.$API.manager().getUsers(params);
        this.managers = response.items.data;
        this.loadManagers = false;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    // Mark task completed
    async markCompleted(id: number) {
      const pos = this.items.findIndex(item => item.id == id);
      const completed = (this.items[pos].completed = !this.items[pos]
        .completed);
      if (completed) {
        this.items[pos].completed_date = moment().format("MM/DD/YYYY HH:mm:SS");
      } else {
        this.items[pos].completed_date = "";
      }
      try {
        const params = {
          completed: completed
        };

        // Set the count of the tasks left for this user
        const count = this.items.filter(
          item => item.completed == 0 && item.assigned_to.id == this.user.id
        ).length;
        await this.$store.dispatch("task/set", count);

        const response = await this.$API.user().updateTask(id, params);
        if (response.success) {
          await this.$store.dispatch("alert/showSuccess", response.message);
        } else {
          await this.$store.dispatch("alert/showError", response.message);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    // Row datatable background
    rowBackground(item: any) {
      if (item.completed) {
        return "table-completed";
      } else if (this.checkDeadlineData(item)) {
        return "";
      }
    },
    statusColumn(item: any) {
      const deadline = this.checkDeadlineData(item);
      const data = {
        icon: "mdi-clock-edit-outline",
        title: "In progress",
        color: "amber"
      };
      if (deadline && !item.completed) {
        data.icon = "mdi-clock-alert-outline";
        data.title = "Expired";
        data.color = "red";
      } else if (item.completed) {
        data.icon = "mdi-clock-check";
        data.title = "Completed on " + item.completed_date;
        data.color = "success";
      }
      return data;
    },
    checkDeadlineData(item: any) {
      const currentDate = moment().format("YYYY-MM-DD");
      const deadline = moment(item.deadline).format("YYYY-MM-DD");
      return deadline < currentDate;
    },
    // Edit task - open dialog
    async editTask(item: object) {
      this.editIndex = this.items.indexOf(item);
      const obj = this.items[this.editIndex];
      this.form = {
        created_by: obj.created_by.id,
        assigned_to: obj.assigned_to.id,
        client_id: obj.client_id.id,
        title: obj.title,
        description: obj.description,
        deadline: obj.deadline,
        status: obj.status,
        id: obj.id
      };
      this.dialogIsOpen = true;
    },

    // close the dialog
    closeDialog() {
      this.dialogIsOpen = false;
      const form: any = this.$refs.form;
      form.reset();
      this.editIndex = -1;
    },

    // close dialog delete
    closeDelete() {
      this.dialogDelete = false;
      this.editIndex = -1;
    },

    deleteItem(test: number) {
      this.editIndex = this.items.findIndex(item => item.id == test);
      this.dialogDelete = true;
    },

    async deleteItemConfirm(item: any) {
      try {
        const id = this.items[this.editIndex]["id"];
        this.items.splice(this.editIndex, 1);
        this.closeDelete();
        const response = await this.$API.user().deleteTask({ id: id });
        if (response.success) {
          await this.$store.dispatch("alert/showSuccess", response.message);
        } else {
          await this.$store.dispatch("alert/showError", response.message);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getTasksByManager(manager_id: any) {
      this.searchClient = manager_id !== "All";
      const params = {
        client_id: manager_id === "All" ? this.clientID : (null as any),
        assigned_to: manager_id === "All" ? (null as any) : manager_id
      };
      await this.filterTasksHandler(params, true);
    },
    async searchTasksByManger() {
      const params = {
        client_id: this.filter.client,
        assigned_to: this.filter.manager
      };
      await this.filterTasksHandler(params, false);
    },
    async filterTasksHandler(params: object, managerFilter: boolean) {
      try {
        this.loading = true;
        const response = await this.$API.user().getTasks(params);
        if (response.success) {
          this.items = response.data;
          if (managerFilter) {
            this.clientsByManager = response.data;
          }
        } else {
          await this.$store.dispatch("alert/showError", response.message);
        }
        this.loading = false;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  },

  computed: {
    filteredItems() {
      if (this.showCompletedItems) {
        return this.items;
      } else {
        return this.items.filter(item => {
          return item.completed == 0;
        });
      }
    },
    tableHeaders() {
      if (this.user.role !== "manager") {
        return [
          ...this.headers,
          {
            text: "Actions",
            value: "actions",
            sortable: false
          }
        ];
      } else {
        return this.headers;
      }
    },
    managersData() {
      // Create a new array with the "All" manager object at the top
      const newManager = {
        name: "All",
        email: ""
      };
      return [newManager, ...this.managers];
    }
  }
});
